import {
  BrowserRouter as Router,
  Routes,
  Route,
} from 'react-router-dom'
import NewHome from './newpage/Home';
// import { ToastContainer } from 'react-toastify';
// import './App.scss';
// import 'react-toastify/dist/ReactToastify.css';
// import Home from './routes/Home';
// import Wallet from './routes/wallet';
// import HttpPage from './routes/Request';
// import Header from './components/Header/PC/index';
// import Footer from './components/Footer';
// import ModelPage from './routes/model';
// import MobileHeader from './components/Header/Mobile';
// // import { isMobile } from './helpers/utilities';
import ScrollMagic from 'scrollmagic';
import { useEffect } from 'react';
import Sign from './newpage/Sign';

function App() {
  useEffect(() => {
    const controller = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 0.5, loglevel: 1, }, });

    const app = document.getElementById('About');
    console.log('%c🀅 app', 'color: #0088cc; font-size: 20px;', app);
    if(app){
      // @ts-ignore
      const scene1 = new ScrollMagic.Scene({ duration: app.clientHeight, triggerElement: '#About' })
      .setClassToggle('#nav-About', 'active') // add class toggle
      .addTo(controller);
      scene1.update(true);
      const toolkit = document.getElementById('Products');
      // @ts-ignore
      new ScrollMagic.Scene({ duration: toolkit.clientHeight, triggerElement: '#Products' })
        .setClassToggle('#nav-Products', 'active') // add class toggle
        .addTo(controller);
      const roadmap = document.getElementById('Join');
      // @ts-ignore
      new ScrollMagic.Scene({ duration: roadmap.clientHeight, triggerElement: '#Join' })
        .setClassToggle('#nav-Join', 'active') // add class toggle
        .addTo(controller);

    }
    
    // const careers = document.getElementById('careers');
    // // @ts-ignore
    // new ScrollMagic.Scene({ duration: careers.clientHeight, triggerElement: '#careers' })
    //   .setClassToggle('#nav-careers', 'active') // add class toggle
    //   .addTo(controller);
  })
  return (
    <Router>
      {/* <div className="mobileHeader">
        <MobileHeader />
      </div>
      <div className="pcHeader">
        <Header />
      </div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/wallet" element={<Wallet />} />
        <Route path="/http" element={<HttpPage />} />
        <Route path='/model' element={<ModelPage />} />
      </Routes>
      <Footer />
      <ToastContainer theme="dark" autoClose={3000} hideProgressBar /> */}
      <Routes>
      <Route path="/" element={<NewHome />} />
      <Route path='/wallet_login' element={<Sign />} />
      </Routes>
    </Router>
  );
}

export default App;
