import { BigNumber, ethers } from 'ethers';

class MetaMaskTool {
  private static _ins: MetaMaskTool;

  static get instance(): MetaMaskTool {
    return this._ins || (this._ins = new MetaMaskTool());
  }

  provider: ethers.providers.Web3Provider | undefined;
  accountAddress: string = '';

  wearableContractAddress: string = '0x02501a32a61c75af8d760154a2cd4e1d0a1104fa';
  DNAContractAddress: string = '0x25f27573ccfd931cb5160fb897568863e3757b84';

  wearableABI = [{ "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "buy", "outputs": [], "stateMutability": "payable", "type": "function" }];
  DNAABI = [{ "inputs": [{ "internalType": "uint256", "name": "tokenId", "type": "uint256" }], "name": "claim", "outputs": [], "stateMutability": "nonpayable", "type": "function" }];

  async connect() {
    try {
      // @ts-ignore
      if (!ethereum || !ethereum.isMetaMask) {
        alert('plase install MetaMask.');
        return false;
      }

      // @ts-ignore
      const a = await window.ethereum.request({ method: 'eth_requestAccounts' });
      this.accountAddress = a[0];
      // @ts-ignore
      this.provider = new ethers.providers.Web3Provider(window.ethereum, 'any');
      return this.accountAddress;
    } catch (error) {
      console.log(error)
      alert('MetaMask not install in your browser.');
      return false;
    }
  }

  async getChainId(): Promise<number> {
    //@ts-ignore
    const { chainId } = await this.provider.getNetwork();
    return chainId;
  }

  async sign(msg: string): Promise<string> {
    // @ts-ignore
    const signer = new ethers.providers.Web3Provider(window.ethereum).getSigner();
    const signature = await signer.signMessage(msg);
    return signature;
  }

  async mintDNA(id: string) {
    const contract = new ethers.Contract(this.DNAContractAddress, this.DNAABI, this.provider?.getSigner());
    let tx = await contract.claim(id);
    tx.wait(1);
  }

  async buyWearable(id: string, price: number) {
    const contract = new ethers.Contract(this.wearableContractAddress, this.wearableABI, this.provider?.getSigner());
    let tx = await contract.buy(id, { from: this.accountAddress, value: this.toWei(price) });
    tx.wait(1);
  }

  toWei(price: number): BigNumber {
    return ethers.utils.parseEther(`${price}`);
  }

  toPrice(bigNumber: BigNumber): number {
    return parseInt(ethers.utils.formatEther(bigNumber), 10);
  }
}

export default MetaMaskTool;
