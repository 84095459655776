/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import './index.scss'
import { ReactNode } from 'react'
// import GifPlayer from '../GifPlayer/index'
// import { Link } from 'react-router-dom'

interface Props {
  to: string,
  children?: ReactNode,
  id: string,
}
const NavItem = ({ to, children, id }: Props): JSX.Element => {
  const goToPage = () => {
    const dom = document.getElementById(to);
    if (dom) {
      dom.scrollIntoView({ behavior: 'smooth' });
      if (to === 'backers') {
        setTimeout(() => {
          dom.scrollIntoView({ behavior: 'smooth' });
        }, 1000)
      }
    }
  }

  return (
    <div className='header-nav-item' id={id} onClick={goToPage}>
      {children}
    </div>
  );
}


const NewHome = (): JSX.Element => {
  // const [playing, setPlaying] = useState<any>(false)
  // const [playing1, setPlaying1] = useState<any>(false)
  // const [playing2, setPlaying2] = useState<any>(false)
  // const [playing3, setPlaying3] = useState<any>(false)

  return (
    <>
      <div className="home">
        <div className="topBg">
          {/* <img src={require('../../assets/img/top_bg.png').default} alt="" height='100%'/> */}
        </div>
        <div className="header">
          <div className="header-container">
            <div className="logo">
              <img src={require('../../assets/img/logo.png').default} alt="logo" />
            </div>
            <div className="nav">
              <ul>
                <li>
                  <NavItem to='About' id='nav-About'>
                    <div className='header-nav-item-title'>
                      About
                    </div>
                  </NavItem>
                </li>
                <li>
                  <NavItem to='Products' id='nav-Products'>
                    <div className='header-nav-item-title'>
                      Products
                    </div>
                  </NavItem>
                </li>
                <li>
                  <NavItem to='Join' id='nav-Join'>
                    <div className='header-nav-item-title'>
                      Join us
                    </div>
                  </NavItem>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="body">

          <div className="part1" id='About'>
            <ul>
              <li className='item1 item'>
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-offset="200"
                  data-aos-duration="500"
                >
                  <div className="bg">
                    <div className="gif_player">
                      <img src={require('../../assets/img/p1.gif').default} alt="" />
                    </div>
                    {/* <GifPlayer 
                   toggle={
                     ()=>{
                      setPlaying(true)
                     }
                   }
                   playing={playing}
                   auto
                    gif={require('../../assets/img/p1.gif').default} still={require('../../assets/img/p1.png').default}></GifPlayer> */}
                  </div>
                </div>
                <div
                  className="text"
                  data-aos="fade-left"
                  data-aos-offset='-3000'
                  data-aos-duration="800"
                >
                  <div className="title">NEXT GENERATION LABS</div>
                  <p
                    className='des'
                  // data-aos="fade-up-left" 
                  // data-aos-delay='100'
                  // data-aos-offset='-3000'
                  // data-aos-duration="2000"
                  // data-aos-anchor-placement="center-bottom"
                  >
                    " The next generation of Web3 is to let everyone, I mean like <span>EVERYONE!!!</span> get into a new world of <span>FUN</span> and <span>HARVEST!</span>
                  </p>
                </div>
              </li>
              <li className='item2 item even'>
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-offset="-300"
                  data-aos-duration="700"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <div className="bg">
                    <div className="gif_player">
                      <img src={require('../../assets/img/p2.gif').default} alt="" />
                    </div>
                    {/* <GifPlayer  
                      toggle={
                        ()=>{
                         setPlaying1(true)
                        }
                      }
                      playing={playing1}
                   gif={require('../../assets/img/p2.gif').default} still={require('../../assets/img/p2.png').default}
                   ></GifPlayer> */}
                  </div>
                </div>
                <div
                  className="text"
                  data-aos="fade-right"
                  // data-aos-offset='-3000'
                  data-aos-duration="1500"
                  data-aos-anchor-placement="bottom-bottom"
                >
                  <div className="title">SOCIAL PARADISE</div>
                  <p className='des'>
                    " Here we have the best Web3 social applications - easy to use, full of fun, and super rewarding!!! So...What can you do here?
                  </p>
                  <p className='mt-40'>
                    <span>CREATE</span>, <span>CONNECT</span>, and <span>COLLABORATE!</span>
                  </p>
                </div>
              </li>
              <li className='item3 item'>
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-offset="-200"
                  data-aos-duration="700"
                >
                  <div className="bg">
                    <div className="gif_player">
                      <img src={require('../../assets/img/p3.gif').default} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="text"
                  data-aos="fade-left"
                  data-aos-duration="1500"
                >
                  <div className="title">
                    ENDLESS POSSIBILITIES
                  </div>
                  <p className='des'>
                    " You can be what you want to be!!! Express yourself and experience the world with AR, AI, and blockchain technology!
                  </p>
                </div>
              </li>
              <li className='item4 item even'>
                <div
                  className="img"
                  data-aos="fade-up"
                  data-aos-offset="-200"
                  data-aos-duration="700"
                >
                  <div className="bg">
                    <div className="gif_player">
                      <img src={require('../../assets/img/p4.gif').default} alt="" />
                    </div>
                  </div>
                </div>
                <div
                  className="text"
                  data-aos="fade-right"
                  data-aos-duration="1500"
                >
                  <div className="title">BE POWERFUL!</div>
                  <p className='des'>
                    " Diverse communities can join us and make a difference in the world of Web3!!! Let's build it together!
                  </p>

                </div>
              </li>
            </ul>
            <div className="spec">
              <img src={require('../../assets/img/spec.png').default} alt="" width={'100%'} />
            </div>
          </div>
          <div className="Products" id='Products'>
            <h1
              className='tit'
              data-aos="fade-right"
              data-aos-duration="700"
            >Products</h1>
            <ul className='list'>
              <li className='item'>
                <div
                  className="img"
                  data-aos-offset="-300"
                  data-aos="fade-down-right"
                  data-aos-duration="800"
                  data-aos-anchor-placement="top-center"
                >
                  <a href="https://crushe.io/" target={'_blank'}>

                    <div className="img_con">
                      <img src={require('../../assets/img/prod1.jpeg').default} alt="" width={'100%'} />
                    </div>
                  </a>

                </div>

                <div className="text">
                  <h5 className='title' data-aos="fade-left" data-aos-duration="300"><span>CRUSHE</span></h5>
                  <h6 className="info" data-aos="fade-left" data-aos-duration="500">Match, date, & meet new people</h6>
                  <p className="des" data-aos="fade-left" data-aos-duration="600">
                    Meet, date, and connect with cool people from crypto communities and beyond. Express and build your social influence with NFT-native content. Unlike in big Web2 dating apps, you get rewarded for being attractive! You receive tokens for meaningful social interactions on our platform.
                  </p>
                  <div className="lastText" data-aos="fade-left" data-aos-duration="700">
                    <div className='inner'>More than just a crush.</div>
                  </div>
                </div>
              </li>
              <li className='item'>
                <div
                  className="img"
                  data-aos-offset="-300"
                  data-aos="fade-up-right"
                  data-aos-duration="800"
                  data-aos-anchor-placement="top-center"
                >
                  <div className="img_con">
                    <img src={require('../../assets/img/prod2.png').default} alt="" width={'100%'} />
                  </div>
                </div>
                <div className="text">
                  <h5 className='title' data-aos="fade-left" data-aos-duration="300"><span>DUMMY PUPPY</span></h5>
                  <h6 className="info" data-aos="fade-left" data-aos-duration="500">Puppy & social games</h6>
                  <p className="des" data-aos="fade-left" data-aos-duration="600">
                    Dummy puppy is a web3 social game built around a real-world metaverse. By walking NFT puppies and collecting their poops outside in Augmented Reality, users get token rewards. In the meantime, they are also hunting for treasure, collecting stray dogs, or having fun in virtual game parks through our adventure map, together with their friends. Furthermore, our puppies are designed to be 24/7 AI companions through training, nurturing, breeding, fostering, and more.
                  </p>
                  <p className="lastText" data-aos="fade-left" data-aos-duration="700">
                    <span className="inner">“Dummy Puppy for dummy owners</span>
                  </p>
                </div>
              </li>
              <li className='item'>
                <div
                  className="img"
                  data-aos-offset="-300"
                  data-aos="fade-down-right"
                  data-aos-duration="800"
                  data-aos-anchor-placement="top-center"
                >
                  <div className="img_con">
                    <img src={require('../../assets/img/prod3.png').default} alt="" width={'100%'} />
                  </div>
                </div>
                <div className="text">
                  <h5 className='title' data-aos="fade-left" data-aos-duration="300"><span>BOO UNIVERSE</span></h5>
                  <h6 className="info" data-aos="fade-left" data-aos-duration="500">3D avatar, chat & meet</h6>
                  <p className="des" data-aos="fade-left" data-aos-duration="600">
                    Live a magical life with your unique 3D avatar in BOO Universe. Buy, create and trade digital fashion. Decorate and invite friends to your space. Play fun social games and meet fun people. Build the BOO community together.
                  </p>
                  <p className="lastText" data-aos="fade-left" data-aos-duration="700">
                    <span className="inner">“Boo universe for cool creatures</span>
                  </p>
                </div>
              </li>
              <li className='item'>
                <div
                  className="img"
                  data-aos-offset="-300"
                  data-aos="fade-up-right"
                  data-aos-duration="800"
                  data-aos-anchor-placement="top-center"
                >
                  <div className="img_con">
                    <img src={require('../../assets/img/prod4.png').default} alt="" width={'80%'} />
                  </div>
                </div>
                <div className="text">
                  <h5 className='title' data-aos="fade-left" data-aos-duration="300"><img src={require('../../assets/img/prod4_title.png').default} alt="" /></h5>
                  <h6 className="info" data-aos="fade-left" data-aos-duration="500">Something mysterious!!!</h6>
                  <p className="des">

                  </p>
                  <p className="lastText" data-aos="fade-left" data-aos-duration="700">
                    <span className="inner">“Check out this mysterious project later!</span>
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <div className="spec">
            <img src={require('../../assets/img/spec.png').default} alt="" width={'100%'} />
          </div>
          {/* <div className="Partners" id='Partners'>
            <h1 
            className='tit'
            data-aos="fade-right" 
            // data-aos-offset="-1000"
            data-aos-duration="700"
            >Partners</h1>
            <ul className='list'>
              <li className="item"  data-aos="flip-left" data-aos-anchor-placement="top-center" data-aos-duration="500">
                <img src={require('../../assets/img/part1.png').default} alt="" width={'100%'}/>
              </li>
              <li className="item" data-aos="flip-up" data-aos-anchor-placement="top-center" data-aos-duration="500">
                <img src={require('../../assets/img/part2.png').default} alt="" width={'100%'}/>
              </li>
              <li className="item" data-aos="flip-right" data-aos-anchor-placement="top-center" data-aos-duration="500">
                <img src={require('../../assets/img/part3.png').default} alt="" width={'100%'}/>
              </li>
            </ul>
          </div> */}
          {/* <div className="spec">
            <img src={require('../../assets/img/spec.png').default} alt="" width={'100%'}/>
          </div> */}
          <div className="join" id='Join'>
            <h1 className='tit'>JOIN US</h1>
            <div className="joinBtn">
              <a href='mailto:guoyuchen@nxglabs.io' className='btn'>
                <img src={require('../../assets/img/Mail.png').default} alt="" />
                RIGHT NOW
              </a>
            </div>
          </div>
        </div>
        {/* <div className="bottomBg">
        </div> */}
        <div className="footer">
          <p>Copyright © 2021-2022 NXG LABS All Rights Reserved.</p>
        </div>

      </div>
    </>
  );
}

export default NewHome;